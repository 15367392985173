import { ToastIntent, ToastPosition } from "@fluentui/react-components";
import { Dispatch, ReactNode, RefObject, SetStateAction } from "react";

import { PullRequest } from "../types/pullRequest";
import { BaseApiService } from "services/api-service";
import { Workspace } from "features/workspaces/types";
import { Preview } from "types/preview";
import { GitHost } from "features/auth";

export enum CollaborateContentType {
  "conversation",
  "newIssue",
  "userDetails",
}

export type CollaborateContextValues = {
  contentType: CollaborateContentType;
  setContentType: Dispatch<SetStateAction<CollaborateContentType>>;
};

export type ConversationsContextValues = {
  pullRequestData: PullRequest | void | undefined | null;
  gitHost: GitHost;
  isPending: boolean;
  apiProvider: BaseApiService | null;
  workspaceId: string;
  installationId: string | undefined;
  commentsContainerRef: RefObject<any> | null;
  commentFormRef: RefObject<any> | null;
  error: Error | null;
};

export type PreviewContextValues = {
  previewData: Preview | null | undefined;
  error: Error | null;
  isPending: boolean;
};

export type WorkspacesContextValues = {
  workspacesData: Workspace[];
  currentWorkspace: Workspace | null;
};

export type NotifyUserOptions = {
  title: string | ReactNode;
  type: ToastIntent;
  description?: string | ReactNode;
  position?: ToastPosition;
  timeoutInSeconds?: number;
  toastId?: string;
};

export type NotificationContextValues = {
  notifyUser: (_options: NotifyUserOptions) => void;
  dismissAllNotifications: () => void;
  dismissNotification: (_toastId: string) => void;
  updateNotification: (_toastId: string, _options: NotifyUserOptions) => void;
};

export type NotificationContextProps = {
  children: ReactNode;
  toasterId: string;
};

export type ApiServiceContextValues = {
  apiProvider: BaseApiService;
};

export type ExtraUserInfo = {
  screenName?: string;
};
