import { useState, createContext } from "react";
import { useLocation } from "react-router-dom";

const ctxValues = {
  drawerOpen: true,
  userTouched: false,
  toggleDrawer: () => {},
  clearUserTouch: () => {},
};

export const DrawerContext = createContext<DrawerCtxProps>(ctxValues);

export function DrawerProvider({ children }: any) {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userTouched, setUserTouched] = useState(false);

  const toggleDrawer = () => {
    if (previewPath.test(pathname)) {
      setUserTouched(true);
    }
    setDrawerOpen(!drawerOpen);
  };

  const clearUserTouch = () => {
    setUserTouched(false);
  };

  return (
    <DrawerContext.Provider
      value={{
        userTouched,
        drawerOpen,
        clearUserTouch,
        toggleDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export const previewPath = /previews\/\d+$/;
type DrawerCtxProps = {
  drawerOpen: boolean;
  userTouched: boolean;
  toggleDrawer: Function;
  clearUserTouch: Function;
};
