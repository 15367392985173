import { createContext, useState } from "react";

import { CollaborateContextValues, CollaborateContentType } from "./types";

const CollaborateContext = createContext<CollaborateContextValues>({
  contentType: CollaborateContentType.conversation,
  setContentType: () => {},
});

function CollaborateProvider({ children }: any) {
  const [contentType, setContentType] = useState<CollaborateContentType>(CollaborateContentType.conversation);

  return (
    <CollaborateContext.Provider
      value={{
        contentType,
        setContentType,
      }}
    >
      {children}
    </CollaborateContext.Provider>
  );
}

export { CollaborateContext, CollaborateProvider };
