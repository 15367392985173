import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { QuillEndpoints } from "client/backend/endpoints";
import { FIVE_MINUTES } from "config/query";
import { useAxios } from "context/AxiosContext";
import { Preview } from "types/preview";
import { QueryConfig } from "utils/reactQuery";

export const getPreview = (
  workspaceId: string,
  previewId: string,
  axiosClient: AxiosInstance,
): Promise<Preview | null> => {
  return axiosClient.get(QuillEndpoints.preview(workspaceId, previewId));
};

// Take advantage of TanStack's caching and query management
export const getPreviewQueryOptions = (workspaceId: string, previewId: string, axiosClient: AxiosInstance) =>
  queryOptions({
    queryKey: ["preview", workspaceId, previewId],
    queryFn: () => getPreview(workspaceId, previewId, axiosClient),
    staleTime: FIVE_MINUTES, // 5 minutes,
    placeholderData: keepPreviousData,
  });

type UsePreviewOptions = {
  queryConfig?: QueryConfig<typeof getPreviewQueryOptions>;
  workspaceId: string;
  previewId: string;
};

export const usePreview = ({ queryConfig, workspaceId, previewId }: UsePreviewOptions) => {
  const axiosClient = useAxios();
  return useQuery({
    ...getPreviewQueryOptions(workspaceId, previewId, axiosClient),
    ...queryConfig,
  });
};
