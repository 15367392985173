import { createContext, useState, useEffect, useContext } from "react";

import { Emulator } from "../client";
import { PreviewContext } from "./PreviewContext";
import { useLocation } from "react-router-dom";

const PortContext = createContext({
  vncStream: "",
  androidSerial: "",
  pkgName: "",
  logsUrl: "",
});

function PortProvider({ children }: any) {
  const [androidSerial, setAndroidSerial] = useState("");
  const [vncStream, setVncStream] = useState("");
  const [pkgName, setPkgName] = useState("");
  const [logsUrl, setLogsUrl] = useState("");
  const { previewData } = useContext(PreviewContext);
  const { search } = useLocation();
  const showFlows = new URLSearchParams(search).get("showFlows");

  useEffect(() => {
    if (!previewData && !showFlows) {
      return;
    }

    let pkg = "";
    let downloadUrl = "";
    let owner = "";

    // TODO: Remove once flows is ready
    if (showFlows) {
      pkg = "com.example.flavor_find";
      downloadUrl = "apks/flavor_find/3";
      owner = "quilldevtools";
    } else {
      pkg = previewData!.pkg;
      downloadUrl = previewData!.downloadUrl;
      owner = previewData!.owner;
    }

    const avd = owner;
    const emulator = new Emulator();
    emulator
      .start(avd)
      .then((res) => {
        res
          .json()
          .then((body: { androidSerial: string; vncStream: string; netLogsUrl: string; netProxyPort: string }) => {
            setAndroidSerial(body.androidSerial);
            setVncStream(body.vncStream);
            setLogsUrl(body.netLogsUrl);

            let decodedUrl = "";
            if (downloadUrl) {
              decodedUrl = downloadUrl;
            }

            if (pkg) {
              setPkgName(pkg);
            }

            const newEmulator = new Emulator();
            newEmulator
              .install(pkg, decodedUrl, body.androidSerial, body.netProxyPort)
              .catch((err) => console.log(err));
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [previewData, showFlows]);

  return (
    <PortContext.Provider
      value={{
        androidSerial,
        vncStream,
        pkgName,
        logsUrl,
      }}
    >
      {children}
    </PortContext.Provider>
  );
}

export { PortContext, PortProvider };
