import { Outlet } from "react-router-dom";

import { DashboardLayout } from "components/layouts/dashboard/DashboardLayout";
import { useContext } from "react";
import { WorkspacesContext, DrawerProvider } from "context";
import { GitHostProvider } from "context/GitHostContext";

export function DashboardRoute() {
  const { currentWorkspace } = useContext(WorkspacesContext);
  if (!currentWorkspace) {
    return null;
  }
  return (
    <>
      <GitHostProvider>
        <DrawerProvider>
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        </DrawerProvider>
      </GitHostProvider>
    </>
  );
}
