import { useClasses } from "./dashboardLayout.styles";
import { useContext, useEffect, useState } from "react";
import { mergeClasses } from "@fluentui/react-components";
import { DrawerContent } from "./DrawerContent";
import { DrawerTop } from "./DrawerTop";
import { Hamburger, NavDrawer, NavDrawerBody, NavDrawerHeader } from "@fluentui/react-nav-preview";
import { DashboardNavValue } from "./types";
import { useLocation } from "react-router-dom";
import { QuillRoutes } from "config/routes";
import { DrawerContext, previewPath, WorkspacesContext } from "context";

type DashboardLayoutProps = {
  children: React.ReactNode;
};

export function DashboardLayout(props: DashboardLayoutProps) {
  const { children } = props;
  const classes = useClasses();
  const { pathname } = useLocation();
  const { currentWorkspace } = useContext(WorkspacesContext);
  const { drawerOpen, toggleDrawer, clearUserTouch } = useContext(DrawerContext);
  const workspaceId = (currentWorkspace && currentWorkspace.id) ?? "";

  const [selectedNavValue, setSelectedNavValue] = useState<DashboardNavValue>(DashboardNavValue.previews);

  useEffect(() => {
    const getCurrentNav = () => {
      if (pathname.startsWith(QuillRoutes.getPreviews(workspaceId ?? ""))) {
        return DashboardNavValue.previews;
      }

      if (pathname.startsWith(QuillRoutes.getMembers(workspaceId ?? ""))) {
        return DashboardNavValue.members;
      }
      if (pathname === QuillRoutes.createFlow(workspaceId)) {
        return DashboardNavValue.flows;
      }
      if (pathname.startsWith(QuillRoutes.getSettings(workspaceId ?? ""))) {
        return DashboardNavValue.settings;
      }
      return DashboardNavValue.previews;
    };

    if (!previewPath.test(pathname) && clearUserTouch) {
      clearUserTouch();
    }

    const currentNav = getCurrentNav();
    if (currentNav !== selectedNavValue) {
      setSelectedNavValue(currentNav);
    }
  }, [pathname, workspaceId, selectedNavValue, clearUserTouch]);

  return (
    <div className={mergeClasses(classes.drawerContainer)}>
      <Hamburger data-testid="hamburger-menu" className={classes.hamburgerDrawer} onClick={() => toggleDrawer()} />
      <NavDrawer
        open={drawerOpen}
        type="inline"
        size="medium"
        selectedValue={selectedNavValue}
        className={classes.navDrawer}
      >
        <NavDrawerHeader className={classes.drawerHeader}>
          <DrawerTop />
        </NavDrawerHeader>
        <NavDrawerBody className={classes.drawerBody}>
          <DrawerContent />
        </NavDrawerBody>
      </NavDrawer>
      <div className={classes.dashBoardContent}>{children}</div>
      {/* </div> */}
    </div>
  );
}
