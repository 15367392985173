import { BASE_URL } from "./constants";

export class Emulator {
  async start(avd: string) {
    const reqOptions = this.createReqOptions({ action: "start", avd });
    return fetch(`${BASE_URL}/emulator/start`, reqOptions);
  }

  async stop(androidSerial: string) {
    // TODO: pass repo, org & pr number as body
    const reqOptions = this.createReqOptions({ action: "stop", androidSerial });
    return fetch(`${BASE_URL}/emulator/${androidSerial}/stop`, reqOptions);
  }

  async install(pkg: string | null, url: string | null, androidSerial: string, proxyPort: string) {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        downloadUrl: url,
      }),
    };

    return fetch(`${BASE_URL}/emulator/${androidSerial}/install?pkgName=${pkg}&port=${proxyPort}`, reqOptions);
  }

  async getElement(x: number, y: number) {
    // TODO: use dynamic android serial
    return fetch(`${BASE_URL}/emulator/emulator-5554/element?x=${x}&y=${y}`);
  }

  async getLogDetail(logsUrl: string, logId: string, resourceType: string) {
    const [proto, domain, port] = logsUrl.split(":");
    const scheme = proto === "ws" ? "http" : "https";
    return fetch(`${scheme}://${domain}/emulator/net/${port}/logs/${logId}/${resourceType}`, {});
  }

  private createReqOptions(reqBody: { action: string; androidSerial?: string; avd?: string }) {
    return {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
      keepalive: true,
    };
  }
}
