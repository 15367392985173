import { createContext, useContext } from "react";

import { PreviewContextValues } from "./types";
import { usePreview } from "services/preview/get-preview";
import { WorkspacesContext } from "./WorkspacesContext";
import { useParams } from "react-router-dom";

const defaultCtxValues = {
  previewData: null,
  error: null,
  isPending: true,
};

const PreviewContext = createContext<PreviewContextValues>(defaultCtxValues);

function PreviewProvider({ children }: any) {
  const { currentWorkspace } = useContext(WorkspacesContext);
  const { previewId } = useParams();
  const {
    data: previewData,
    error,
    isPending,
  } = usePreview({
    workspaceId: currentWorkspace?.id ?? "",
    previewId: previewId ?? "",
  });

  return (
    <PreviewContext.Provider
      value={{
        previewData,
        error,
        isPending,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
}

export { PreviewContext, PreviewProvider };
